import { FC, useEffect, useState } from 'react';
import { Box, useMediaQuery } from '@mui/material';
import { Heading, fontWeight, headingLevel } from '@confidant-health/lib/ui/atoms/typography';
import { useNavigate, useParams } from 'react-router-dom';
import { getAuth } from 'redux/modules/auth/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { profileActionCreators } from 'redux/modules/profile';
import { getProfile } from 'redux/modules/profile/selectors';
import { IDemographicDetails } from 'redux/modules/profile/types';
import { appointmentActionCreators } from 'redux/modules/appointment';
import ScheduleLink from 'pages/provider/schedule/components/schedule-link/ScheduleLink';
import { getMemberNotes } from 'services/member/member.service';
import { IconButton } from '@confidant-health/lib/ui/molecules/icon-button';
import { btnSize } from '@confidant-health/lib/ui/atoms/button';
import CheckPDMP from 'components/v2/CheckPDMP';
import IdentificationCard from 'components/v2/IdentificationCard';
import RenderNotes from 'pages/provider/priorities/notes';
import Medications from 'pages/provider/priorities/medications';
import Diagnoses from 'pages/provider/priorities/diagnoses';
import EnviromentalFactors from 'pages/provider/priorities/enviromental-factors';
import SymptomaticFactors from 'pages/provider/priorities/symptomatic-factors';
import RenderEvaluations from 'pages/provider/priorities/evaluations';
import AppointmentWithEvals from 'pages/provider/priorities/appointments/AppointmentWithEvals';
import { IAppointment } from '@confidant-health/lib/ui/templates/appointment-card';
import dayjs from 'dayjs';
import {
  getNextAppointment,
  getPreviousCompletedAppointment,
} from 'services/appointment/appointment.service';
import { PriorityDetailDrawer } from 'pages/provider/priorities/PriorityDetailDrawer';
import { useQuery } from '@confidant-health/lib/hooks';
import history from 'utils/history';
import { selectEvaluation } from 'redux/modules/conversation/selectors';
import AppointmentScheduling from 'pages/provider/appointments/components/appointment-scheduling';
import AppointmentDetail from 'pages/provider/appointments/components/appointment-detail';
import { sendMagicLink } from '../../../../../services/auth/auth.service';
import { useStyles } from './index.styles';
import { PrioritiesCard } from '../../../sessions/priority-list/PriorityList.constants';
import { showSnackbar } from '../../../../../redux/modules/snackbar';
import RecentCaseNotes from '../../../priorities/case-notes';

// import Comments from './Comments';

const Dashboard: FC = () => {
  const classes = useStyles();
  const { memberId } = useParams();
  const query = useQuery();
  const { isAdmin, meta } = useSelector(getAuth);
  const dispatch = useDispatch();
  const { demographicDetails = {} as IDemographicDetails, selectedPatient } = useSelector(getProfile);
  const evaluations = useSelector(selectEvaluation);
  const [lastAppointment, setLastAppointment] = useState<IAppointment | null>(null);
  const [notes, setNotes] = useState(null);
  const [caseNotesLoader, setCaseNotesLoader] = useState(false);
  const [viewAllType, setViewAllType] = useState<string>(null);
  const [diagnosesReset, setDiagnosesReset] = useState(false);
  const [medicationsReset, setMedicationsReset] = useState(false);
  const [symptomsReset, setSymptomsReset] = useState(false);
  const [envFactorsReset, setEnvFactorsReset] = useState(false);
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('md'));
  const [selectedPriority, setSelectedPriority] = useState(null);
  const [nextAppointment, setNextAppointment] = useState<IAppointment>(null);
  const [viewAppointmentDetail, setViewAppointmentDetail] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [showReschedule, setShowReschedule] = useState(false);
  const [isAppointmentCreated, setIsAppointmentCreated] = useState(false);
  const navigate = useNavigate();

  const onViewAll = type => {
    const name = query.get('name');
    if (name === 'dashboard') {
      navigate(`/${isAdmin ? 'admin' : 'provider'}/members/${memberId}?name=${type}`);
    }
    setSelectedPriority(null);
    setViewAllType(type);
  };

  const fetchNextAppointment = async () => {
    const { data } = await getNextAppointment(memberId, false);
    setNextAppointment(data.appointment);
  };

  const onElementsUpdated = type => {
    switch (type) {
      case PrioritiesCard.Diagnoses: {
        setDiagnosesReset(v => !v);
        break;
      }
      case PrioritiesCard.Medications: {
        setMedicationsReset(v => !v);
        break;
      }
      case PrioritiesCard.SymptomaticFactors: {
        setSymptomsReset(v => !v);
        break;
      }
      case PrioritiesCard.EnvironmentalFactors: {
        setEnvFactorsReset(v => !v);
        break;
      }
      default: {
        console.log('Unknown type updated');
      }
    }
  };

  const fetchNotesCall = () => {
    getMemberNotes({ userId: memberId }, { pageNumber: 0, pageSize: 3 })
      .then(res => {
        if (res.data?.patientContactNotes) {
          setNotes(res.data);
        }
        setCaseNotesLoader(false);
      })
      .catch(error => {
        setCaseNotesLoader(false);
        console.log({ error });
      });
  };

  useEffect(() => {
    setCaseNotesLoader(true);
    const appointmentsQueryParams = {
      orderBy: 'asc',
      page: 0,
      size: 3,
      sortBy: 'startTime',
      statuses: '',
      type: 'CURRENT',
      states: null,
      searchQuery: '',
    };
    fetchNotesCall();
    dispatch(profileActionCreators.fetchDemographicMetadata());
    dispatch(profileActionCreators.fetchDemographicDetails(memberId));
    dispatch(profileActionCreators.fetchDomainTypes(memberId));
    dispatch(profileActionCreators.fetchMemberInsurance({ memberId }));
    dispatch(
      appointmentActionCreators.fetchAppointments({
        queryParams: appointmentsQueryParams,
      }),
    );
  }, []);

  useEffect(() => {
    if (memberId) {
      getPreviousCompletedAppointment(memberId, dayjs().toISOString())
        .then(({ data }) => {
          setLastAppointment(data.appointment as IAppointment);
        })
        .catch(e => {
          console.error(e);
        });
    }
  }, [memberId]);

  useEffect(() => {
    dispatch(
      profileActionCreators.selectedPatient({
        member: {
          id: memberId,
          firstName: demographicDetails?.firstName || '',
          lastName: demographicDetails?.lastName || '',
          fullName: demographicDetails?.fullName || '',
          nickName: demographicDetails?.nickName || '',
          photo: null,
          uuid: demographicDetails?.uuid || '',
        },
        email: demographicDetails?.email || '',
        dob: demographicDetails?.dateOfBirth || '',
        phone: demographicDetails?.mobilePhone || '',
        fundsInWallet: 0,
        engagement: {
          Status: demographicDetails?.levelOfEngagementStatus || '',
          SubStatus: demographicDetails?.levelOfEngagementStatus || '',
        },
      }),
    );
  }, [demographicDetails]);

  useEffect(() => {
    void fetchNextAppointment();
  }, [isAppointmentCreated]);

  const sendMemberLink = async () => {
    try {
      const response = await sendMagicLink({ memberId });
      if (response.status === 200) {
        dispatch(
          showSnackbar({
            snackType: 'success',
            snackMessage: 'Magic link sent successfully',
          }),
        );
      }
    } catch (err) {
      console.log(err);
      dispatch(
        showSnackbar({
          snackType: 'error',
          snackMessage: err.data?.errors?.[0]?.endUserMessage || 'Something went wrong',
        }),
      );
    }
  };
  // const renderStory = () => (
  //   <Grid container spacing={3}>
  //     <Grid item xs={12} md={12} lg={6}>
  //       <Card
  //         label="Personal story"
  //         actions={[
  //           {
  //             label: 'Read more',
  //             onClick: () =>
  //               navigate(`/${isAdmin ? 'admin' : 'provider'}/members/${memberId}?name=general-information`),
  //           },
  //         ]}
  //       >
  //         <Box sx={{ p: 4 }}>
  //           <Box className={classes.verticalEllipsis}>
  //             Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia
  //             consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim
  //             mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim
  //             velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt
  //             ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit.
  //             Exercitation veniam consequat sunt nostrud amet.
  //           </Box>
  //         </Box>
  //       </Card>
  //     </Grid>
  //     <Grid item xs={12} md={12} lg={6}>
  //       <Card
  //         label="Treatment journey"
  //         actions={[
  //           {
  //             label: 'Read more',
  //             onClick: () =>
  //               navigate(`/${isAdmin ? 'admin' : 'provider'}/members/${memberId}?name=general-information`),
  //           },
  //         ]}
  //       >
  //         <Box sx={{ p: 4 }}>
  //           <Box className={classes.verticalEllipsis}>
  //             Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia
  //             consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim
  //             mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim
  //             velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt
  //             ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit.
  //             Exercitation veniam consequat sunt nostrud amet.
  //           </Box>
  //         </Box>
  //       </Card>
  //     </Grid>
  //   </Grid>
  // );

  // const renderPersonality = () => (
  //   <Grid container spacing={3}>
  //     <Grid item xs={12} md={12} lg={6}>
  //       <Card
  //         label="Anxiety"
  //         badge="12 completed"
  //         actions={[{ label: 'All completions', onClick: () => null }]}
  //       >
  //         <Box sx={{ p: 3 }}>
  //           <Grid container spacing={4}>
  //             <Grid item xs={12} md={4}>
  //               <Box
  //                 className={classes.flexColumn}
  //                 sx={{ flex: 1, gap: 1, p: 2, background: colors.neutral50, borderRadius: 2 }}
  //               >
  //                 <Box className={classes.cardLabel}>first</Box>
  //                 <Box className={classes.cardDescription}>15</Box>
  //                 <Box className={classes.cardSubLabel}>5/6/2023</Box>
  //               </Box>
  //             </Grid>
  //             <Grid item xs={12} md={4}>
  //               <Box
  //                 className={classes.flexColumn}
  //                 sx={{ flex: 1, gap: 1, p: 2, background: colors.neutral50, borderRadius: 2 }}
  //               >
  //                 <Box className={classes.cardLabel}>current</Box>
  //                 <Box className={classes.cardDescription}>60</Box>
  //                 <Box className={classes.cardSubLabel}>5/6/2023</Box>
  //               </Box>
  //             </Grid>
  //             <Grid item xs={12} md={4}>
  //               <Box
  //                 className={classes.flexColumn}
  //                 sx={{ flex: 1, gap: 1, p: 2, background: colors.destructive50, borderRadius: 2 }}
  //               >
  //                 <Box className={classes.cardLabel} sx={{ color: `${colors.destructive500} !important` }}>
  //                   change
  //                 </Box>
  //                 <Box
  //                   className={classes.cardDescription}
  //                   sx={{ color: `${colors.destructive500} !important` }}
  //                 >
  //                   45
  //                 </Box>
  //                 <Box className={classes.cardSubLabel}>5/6/2023</Box>
  //               </Box>
  //             </Grid>
  //           </Grid>
  //         </Box>
  //       </Card>
  //     </Grid>
  //     <Grid item xs={12} md={12} lg={6}>
  //       <Card
  //         label="Depression"
  //         badge="12 completed"
  //         actions={[{ label: 'All completions', onClick: () => null }]}
  //       >
  //         <Box sx={{ p: 3 }}>
  //           <Grid container spacing={4}>
  //             <Grid item xs={12} md={4}>
  //               <Box
  //                 className={classes.flexColumn}
  //                 sx={{ flex: 1, gap: 1, p: 2, background: colors.neutral50 }}
  //               >
  //                 <Box className={classes.cardLabel}>first</Box>
  //                 <Box className={classes.cardDescription}>15</Box>
  //                 <Box className={classes.cardSubLabel}>5/6/2023</Box>
  //               </Box>
  //             </Grid>
  //             <Grid item xs={12} md={4}>
  //               <Box
  //                 className={classes.flexColumn}
  //                 sx={{ flex: 1, gap: 1, p: 2, background: colors.neutral50 }}
  //               >
  //                 <Box className={classes.cardLabel}>current</Box>
  //                 <Box className={classes.cardDescription}>60</Box>
  //                 <Box className={classes.cardSubLabel}>5/6/2023</Box>
  //               </Box>
  //             </Grid>
  //             <Grid item xs={12} md={4}>
  //               <Box
  //                 className={classes.flexColumn}
  //                 sx={{ flex: 1, gap: 1, p: 2, background: colors.success50 }}
  //               >
  //                 <Box className={classes.cardLabel} sx={{ color: `${colors.success500} !important` }}>
  //                   change
  //                 </Box>
  //                 <Box className={classes.cardDescription} sx={{ color: `${colors.success500} !important` }}>
  //                   45
  //                 </Box>
  //                 <Box className={classes.cardSubLabel}>5/6/2023</Box>
  //               </Box>
  //             </Grid>
  //           </Grid>
  //         </Box>
  //       </Card>
  //     </Grid>
  //   </Grid>
  // );

  const viewApptDetail = appt => {
    setSelectedAppointment(appt);
    setViewAppointmentDetail(true);
  };

  const onCloseViewAllDrawer = () => {
    setViewAllType(null);
    setSelectedPriority(null);
  };

  const onStartSession = (appointment: IAppointment) => {
    history.push(`/provider/appointments/current/${appointment?.appointmentId}/session`);
  };

  const onCloseDrawer = () => {
    setSelectedAppointment(null);
    setViewAppointmentDetail(false);
  };

  const toggleRescheduleDrawer = () => {
    setShowReschedule(!showReschedule);
  };

  const handleViewAllCaseNotes = () => {
    navigate(`/${isAdmin ? 'admin' : 'provider'}/members/${memberId}?name=notes`);
  };

  return (
    <Box className={classes.headingWrapper}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Heading className={classes.heading} level={headingLevel.XL} weight={fontWeight.BOLD}>
          Dashboard
        </Heading>
        <IconButton icon="wand-outlined" size={btnSize.SMALL} onClick={sendMemberLink}>
          <Box className={classes.magicLink}>Send magic link</Box>
        </IconButton>
      </Box>
      <Box className={classes.flexColumn} sx={{ gap: 3, marginTop: '-16px' }}>
        <RenderNotes memberId={selectedPatient?.member?.id} hideIcon={false} />
        <RenderEvaluations isWaiting={!isMobile} memberId={selectedPatient?.member?.id} />
        {nextAppointment && (
          <AppointmentWithEvals
            appointment={nextAppointment}
            fetchEvaluationsOnLoad
            isPast={false}
            title="Next appointment"
            hideNotes
            viewApptDetail={viewApptDetail}
            showViewDetails
          />
        )}
        {!isAdmin && (
          <ScheduleLink
            cardTitle="Scheduling link"
            copyLinkText="Copy scheduling link"
            generateLinkText="Generate scheduling link"
            mainLink={`book-appointment?memberId=${selectedPatient?.member?.id}&selectedProvider=${meta.userId}`}
          />
        )}
        <SymptomaticFactors
          memberId={selectedPatient?.member?.id}
          onViewAll={onViewAll}
          onClick={(item, type) => {
            setSelectedPriority(item);
            setViewAllType(type);
          }}
          reset={symptomsReset}
        />
        <RecentCaseNotes
          notes={notes?.patientContactNotes}
          total={notes?.totalRecords}
          memberId={memberId}
          fetchNotes={fetchNotesCall}
          caseNotesLoader={caseNotesLoader}
          onViewAll={handleViewAllCaseNotes}
        />
        {lastAppointment && (
          <AppointmentWithEvals
            appointment={lastAppointment}
            fetchEvaluationsOnLoad
            isPast
            hideNotes
            viewApptDetail={viewApptDetail}
            showViewDetails
          />
        )}
        <Diagnoses
          // title="Active diagnosis"
          memberId={selectedPatient?.member?.id}
          hideNotes
          onViewAll={onViewAll}
          onClick={(item, type) => {
            setSelectedPriority(item);
            setViewAllType(type);
          }}
          reset={diagnosesReset}
        />
        <EnviromentalFactors
          memberId={selectedPatient?.member?.id}
          onViewAll={onViewAll}
          onClick={(item, type) => {
            setSelectedPriority(item);
            setViewAllType(type);
          }}
          reset={envFactorsReset}
        />
        <Medications
          // title="Active medications"
          memberId={selectedPatient?.member?.id}
          hideNotes
          onViewAll={onViewAll}
          onClick={(item, type) => {
            setSelectedPriority(item);
            setViewAllType(type);
          }}
          reset={medicationsReset}
        />
        <IdentificationCard isSession={false} memberId={selectedPatient?.member?.id} />
        {(demographicDetails?.state === 'Connecticut' ||
          demographicDetails?.state === 'Texas' ||
          demographicDetails?.state === 'Florida' ||
          demographicDetails?.state === 'Virginia' ||
          demographicDetails?.state === 'New Hampshire') && (
          <CheckPDMP patientState={demographicDetails?.state} />
        )}
        {!!viewAllType && (
          <PriorityDetailDrawer
            memberId={selectedPatient?.member?.id}
            selectedItem={selectedPriority}
            type={viewAllType}
            onClose={onCloseViewAllDrawer}
            open={!!viewAllType}
            onElementsUpdated={onElementsUpdated}
          />
        )}
        <AppointmentDetail
          open={viewAppointmentDetail}
          onClose={onCloseDrawer}
          onRescheduleClick={toggleRescheduleDrawer}
          onAcceptAppointment={onStartSession}
          appointment={selectedAppointment}
          evaluations={evaluations}
        />
        <AppointmentScheduling
          open={showReschedule}
          onClose={toggleRescheduleDrawer}
          appointment={selectedAppointment}
          updateAppointment={setSelectedAppointment}
          setIsApptCreated={isCreated => setIsAppointmentCreated(isCreated)}
        />
      </Box>
    </Box>
  );
};

export default Dashboard;
