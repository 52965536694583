import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';

import { Drawer, drawerType } from '@confidant-health/lib/ui/organisms/drawer';
import { Checkbox } from '@confidant-health/lib/ui/atoms/checkbox';
import { FormControlLabel } from '@confidant-health/lib/ui/atoms/form-control-label';
import { TextError } from '@confidant-health/lib/ui/atoms/typography';

import { useStyles } from './Credentials.styles';

type CredentialsProps = {
  isOpen: boolean;
  type?: string;
  onClose: () => void;
  error?: string;
  isSaving?: boolean;
  selectedItems: string[];
  title: string;
  items: { label: string; value: string }[];
  onUpdate: (payload: any) => void;
};

const Credentials: React.FC<CredentialsProps> = ({
  title,
  isOpen,
  items,
  type,
  onClose,
  error,
  selectedItems,
  onUpdate,
}) => {
  const classes = useStyles();
  const [newItems, setNewItems] = useState<string[]>(selectedItems || []);

  const [savingButton, setSavingButton] = useState(false);

  const onUpdateButtonClick = () => {
    setSavingButton(true);
  };

  useEffect(() => {
    if (!isOpen) {
      setSavingButton(false);
    }
  }, [isOpen]);

  useEffect(() => {
    setNewItems(selectedItems || []);
  }, [selectedItems]);

  const onChange = (value: string) => () => {
    const newSelectedCredentials = newItems.includes(value)
      ? newItems.filter(item => item !== value)
      : [...newItems, value];
    setNewItems(newSelectedCredentials);
  };

  const handleSubmit = () => {
    onUpdate({ [type]: newItems });
  };

  return (
    <Drawer
      title={title}
      open={isOpen}
      onClose={onClose}
      variant={drawerType.FORM}
      submitBtnTitle={savingButton ? 'Saving' : 'Update'}
      disableSubmit={savingButton}
      onSubmit={() => {
        onUpdateButtonClick();
        handleSubmit();
      }}
    >
      <Box className={classes.drawerContent}>
        {error && (
          <Box display="flex" justifyContent="center" paddingY={1}>
            <TextError errorMsg={error} />
          </Box>
        )}
        {items?.map(item => (
          <FormControlLabel
            key={item.value}
            control={<Checkbox checked={newItems.includes(item.value)} />}
            onChange={onChange(item.value)}
            label={item.label}
          />
        ))}
      </Box>
    </Drawer>
  );
};

export { Credentials };
