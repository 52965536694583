import * as Yup from 'yup';

const AddMemberSchema = Yup.object().shape(
  {
    firstName: Yup.string().when('lastName', {
      is: lastName => !lastName || lastName.trim() === '',
      then: Yup.string().required('Either first or last name is required'),
    }),
    lastName: Yup.string().when('firstName', {
      is: firstName => !firstName || firstName.trim() === '',
      then: Yup.string().required('Either first or last name is required'),
    }),
    email: Yup.string().required('Email is required'),
    phoneNumber: Yup.string().required('Phone number is required'),
  },
  [['firstName', 'lastName']],
);

export { AddMemberSchema };
