import { AxiosResponse } from 'axios';
import { ApiEndpoints } from 'constants/ApiEndpoints';
import { baseRequest, signOff } from 'utils/HttpClient';

export const login = (payload: {
  emailOrPhone: string;
  password: string;
  type: string;
}): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.LOGIN, payload);
};

export const verifyOtp = (payload: {
  code: string;
  codeType: string;
  emailOrPhone: string;
  userType: string;
}): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.VERIFY_AUTH, payload);
};

export const userAccountRecovery = (userAccountRecoveryParams: {
  email: string;
  type: string;
}): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.USER_ACCOUNT_RECOVERY, userAccountRecoveryParams);
};

export const verifyConfirmationCode = (verifyConfirmationCodeParams): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.VERIFY_CONFIRMATION_CODE, verifyConfirmationCodeParams);
};

export const updatePassword = (updatePasswordParams: {
  email: string;
  newPassword: string;
  confirmPassword: string;
  code?: string;
  userType: string;
}): Promise<AxiosResponse> => {
  const res = baseRequest(ApiEndpoints.UPDATE_PASSWORD, updatePasswordParams);

  return res;
};

export const resendVerificationCode = (resendVerificationCodeParams: {
  emailOrPhone: string;
  codeType: string;
}): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.RESEND_VERIFICATION_CODE, resendVerificationCodeParams);
};

export const resendOtp = (payload: { codeType: string; emailOrPhone: string }): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.RESEND_OTP, payload);
};

export const logout = (): void => {
  return signOff();
};

export const registerPlayerId = (pathParams: { playerId: string }) => {
  return baseRequest(ApiEndpoints.REGISTER_PLAYERID, null, pathParams);
};

export const getSendbirdSessionToken = (): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.GET_SENDBIRD_TOKEN);
};

export const sendMagicLink = (pathParams: { memberId: string }): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.SEND_MAGICLINK, null, pathParams);
};
