// colors
import { colors } from '@confidant-health/lib/colors';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: 40,
  },
  body: { display: 'flex', flexDirection: 'column', gap: 24 },
  // general information
  dot: {
    width: 6,
    height: 6,
    borderRadius: '50%',
    marginRight: 8,
    backgroundColor: colors.neutral400,
  },
  activeDot: {
    backgroundColor: colors.success600,
  },
  stateBadge: {
    borderRadius: 100,
    border: `1px solid ${colors.neutral200}`,
    background: colors.white,
  },
  tabs: {
    '& > div > div': {
      position: 'relative',
      '&:before': {
        content: "''",
        position: 'absolute',
        width: '100%',
        height: 1,
        backgroundColor: colors.neutral100,
        bottom: 0,
      },
    },
    '& button': {
      textTransform: 'none',
      paddingTop: 26,
      paddingBottom: 26,
      paddingLeft: 20,
      paddingRight: 20,
      color: colors.neutral600,
      fontWeight: 700,
      fontSize: 14,
      lineHeight: '20px',
      border: 'none',
      '&.Mui-selected': {
        color: colors.neutral900,
      },
    },
    '& .MuiTabs-indicator': {
      height: 1,
      backgroundColor: colors.neutral900,
    },
  },
  drawer: {
    '& .MuiPaper-root': {
      maxWidth: '640px !important',
    },
    '& .drawerBody': {
      padding: 0,
    },
  },
  video: {
    border: 'none',
    borderRadius: 10,
  },
  infoIcon: {
    color: colors.warning500,
  },
  documentIcon: {
    color: colors.indigo500,
  },
  discrepancyIcon: {
    color: colors.secondary500,
  },
  expiredIcon: {
    color: colors.destructive500,
  },
  expiringSoonIcon: {
    color: colors.orange500,
  },
  successIcon: {
    color: colors.success500,
  },
  disabledIcon: {
    color: colors.neutral400,
  },
  multipleIcon: {
    color: colors.purple700,
  },
  inactiveIcon: {
    color: colors.destructive600,
    '& svg': {
      minWidth: '20px',
      minHeight: '20px',
    },
  },
  waitContainer: {
    position: 'absolute',
    background: 'white',
    width: '100%',
    height: '100vh',
    top: 0,
    left: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  leftContainer: {
    width: 560,
    height: 'calc(100vh - 80.8px)',
    overflowY: 'auto',
    borderRight: `1px solid ${colors.neutral100}`,
  },
  rightContainer: {
    width: 640,
    height: 'calc(100vh - 80.8px)',
    overflowY: 'auto',
    background: colors.neutral25,
    padding: 24,
  },
  uploadForm: {
    display: 'flex',
    flexDirection: 'column',
    gap: 40,
    padding: 32,
    border: `1px solid ${colors.neutral100}`,
    boxShadow: '0px 0px 1px 0px #0000000A, 0px 0px 2px 0px #0000000F, 0px 4px 8px 0px #0000000A',
    borderRadius: 8,
  },
  collapsable: {
    border: `1px solid ${colors.neutral100}`,
    boxShadow: '0px 0px 1px 0px #0000000A, 0px 0px 2px 0px #0000000F, 0px 4px 8px 0px #0000000A',
    borderRadius: 8,
    padding: 16,
  },
  drawerTabs: {
    borderBottom: `1px solid ${colors.neutral100}`,
    height: '72px',
    '& button': {
      color: colors.neutral600,
      fontWeight: 700,
      height: '72px',
      minWidth: '144px',
      textTransform: 'none',
      '&.Mui-selected': {
        color: `${colors.neutral900} !important`,
      },
    },
    '& .MuiTabs-indicator': {
      backgroundColor: colors.neutral900,
    },
  },
  activeBadge: {
    borderColor: colors.success400,
    color: colors.success600,
    fontSize: 12,
  },
  criticalBadge: {
    borderColor: colors.destructive300,
    color: colors.destructive600,
    fontSize: 12,
  },
  errorIcon: {
    width: 80,
    height: 80,
    alignItems: 'center',
  },
  inProgressIcon: {
    width: 100,
    height: 100,
    alignItems: 'center',
  },
  value: {
    textAlign: 'end',
  },
  dateInput: {
    '& .MuiFormControl-root': {
      width: '100%',
      '& .MuiOutlinedInput-root': {
        height: '48px',
      },
    },
  },
  discrepantDateInput: {
    '& .MuiFormControl-root': {
      width: '100%',
      '& .MuiOutlinedInput-root': {
        height: '48px',
        '& fieldset': {
          borderColor: `${colors.destructive500}`,
        },
        '&:hover fieldset': {
          borderColor: `${colors.destructive500}`,
        },
        '&.Mui-focused fieldset': {
          borderColor: `${colors.destructive500}`,
        },
      },
    },
  },
  closeIcon: {
    width: 24,
    height: 24,
    color: colors.primary500,
    cursor: 'pointer',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  checkbox: {
    paddingRight: '8px',
  },
  valueContainer: {
    wordBreak: 'break-word',
    textAlign: 'end',
  },
  fileItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: '8px',
  },
  fileTitleContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: '16px',
  },
  fileTitle: {
    display: 'flex',
    flexDirection: 'column',
  },
  filesContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    marginBottom: '16px',
  },
  fileButtons: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    minWidth: '189px',
    gap: '24px',
  },
  fileThumbnailIcon: {
    width: '32px',
    height: '32px',
  },
  fileViewButton: {
    '& svg': {
      width: 24,
      height: 24,
      marginRight: 8,
    },
    fontSize: '14px',
    minWidth: '65px',
    fontWeight: 700,
  },
  fileDownloadButton: {
    fontSize: '14px',
    fontWeight: 700,
    minWidth: '100px',
    '& svg': {
      width: 18,
      height: 19,
      marginRight: 8,
    },
  },
  inputDiscrepant: {
    borderColor: `${colors.destructive500}`,
  },
  discrepancyMessage: {
    marginTop: '2px',
    paddingRight: '16px',
  },
  inlineIcon: {
    verticalAlign: 'middle',
    marginRight: '8px',
  },
  formBlock: {
    display: 'flex',
    flexDirection: 'column',
    gap: 24,
    padding: 40,
    borderTop: `1px solid ${colors.neutral100}`,
  },
  removeContentBtn: {
    fontSize: 14,
    color: colors.destructive500,
    '& svg': {
      width: 18,
      marginRight: 6,
      '& path': {
        fill: colors.destructive500,
      },
    },
  },
  title: {
    fontSize: 18,
    lineHeight: '24px',
    fontWeight: 'bold',
  },
});
