import { makeStyles } from '@mui/styles';

import { colors } from '@confidant-health/lib/colors';

export const useStyles = makeStyles({
  stateContent: {
    fontWeight: 600,
    width: '100%',
  },
  cardContent: {
    // padding: '24px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  cardSection: {
    padding: '24px',
    borderBottom: `1px solid ${colors.dividerPrimary}`,
    borderBottomLeftRadius: '8px',
    borderBottomRightRadius: '8px',
  },
  wrapperUserProfile: {
    marginBottom: 18,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  profileTimeZone: {
    fontSize: 14,
    fontWeight: '500',
    marginRight: 32,
  },
  nameProfile: {
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '20px',
    margin: 0,
    color: colors.neutral900,
  },
  roleProfile: {
    fontWeight: 600,
    fontSize: 12,
    height: 20,
    lineHeight: '20px',
    padding: '2px 8px',
    borderRadius: 100,
    textTransform: 'capitalize',
  },
  providerRole: {
    background: colors.indigo50,
    color: colors.indigo600,
  },
  badge: {
    padding: '4px 12px',
    fontWeight: 600,
    height: 'auto',
    fontSize: 14,
    lineHeight: '20px',
    borderRadius: 100,
    textTransform: 'capitalize',
  },
  dot: {
    width: 6,
    height: 6,
    borderRadius: '50%',
    marginRight: 8,
    backgroundColor: colors.destructive600,
  },
  activeDot: {
    backgroundColor: colors.success600,
  },
  textDescription: {
    color: colors.neutral700,
    fontSize: 16,
    fontWeight: 500,
    lineHeight: '24px',
  },
  badgeContent: {
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '20px',
    color: colors.neutral700,
    backgroundColor: colors.white,
    border: `1px solid ${colors.neutral200}`,
    padding: '4px 12px',
    borderRadius: 100,
  },
  titleAffiliation: {
    fontWeight: 700,
    fontSize: 16,
    lineHeight: '24px',
    color: colors.neutral900,
    margin: 0,
  },
  descriptionAffiliation: {
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '24px',
    color: colors.neutral600,
    display: 'block',
    padding: 24,
    maxWidth: 150,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '@media (min-width: 1024px)': {
      maxWidth: 500,
    },
  },
  widthAuto: {
    maxWidth: 'unset !important',
  },
  textDateAffiliation: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '20px',
    color: colors.neutral500,
  },
  titleDuration: {
    fontWeight: 700,
    fontSize: 16,
    lineHeight: '24px',
    color: colors.neutral900,
  },
  contentDuration: {
    backgroundColor: colors.white,
    border: `1px solid ${colors.neutral200}`,
    padding: '4px 12px',
    borderRadius: 100,

    '& span': {
      fontWeight: 600,
      fontSize: 14,
      lineHeight: '20px',
      color: colors.neutral700,
    },
  },
  descriptionBox: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '2px',
    marginTop: '15px',
  },
  cutTextWrapper: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    paddingLeft: '2px',
  },
  cutText: {
    display: 'inline-block',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    paddingRight: '20px',
    paddingLeft: '2px',
  },
  aboutText: {
    paddingRight: '20px',
    paddingLeft: '2px',
  },
  textDark: {
    lineHeight: '40px',
    color: colors.neutral900,
  },
  textLight: {
    lineHeight: '19px',
    color: colors.neutral500,
  },
  about: {
    lineHeight: '24px',
    textWrap: 'wrap',
    wordBreak: 'break-all',
  },
  activeIcon: {
    width: '24px',
    height: '24px',
  },
  inactiveIcon: {
    width: '20px',
    height: '20px',
  },
});
