import { FC, memo, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AxiosResponse } from 'axios';

import { useQuery } from '@confidant-health/lib/hooks';
import { INavItemType } from '@confidant-health/lib/ui/templates/side-navigation';

import { profileActionCreators } from 'redux/modules/profile';
import { scheduleActionCreators } from 'redux/modules/schedule';
import { getProfile } from 'redux/modules/profile/selectors';

import { BaseLayout } from 'layouts/base';
import Header from 'components/v2/Header';
import { HeadingFont, HeadingLevel } from 'components/v2/Heading/index.types';

import SelectedComponent from './index.detail.selected';

import { rightSideNavList } from './index.constants';

import { useStyles } from './index.styles';
import {
  caqhDataSummary,
  fetchCaqhCredentials,
  fetchNavItemStatus,
} from '../../../services/datalab/datalab.service';
import { getAuth } from '../../../redux/modules/auth/selectors';
import { getCaqhData } from './common/utils.constants';
import { CardStatus } from './common/card/profile-section-card';

const ProfileV2: FC = memo(() => {
  const classes = useStyles({});
  const dispatch = useDispatch();
  const query = useQuery();

  const { providers, profile } = useSelector(getProfile);
  const { meta } = useSelector(getAuth);

  const [selectedScreen, setSelectedScreen] = useState<string>('');
  const [navItemStatus, setNavItemStatus] = useState(null);

  const getNavItemStatuses = async () => {
    const status = await fetchNavItemStatus({ providerId: meta?.userId });
    const statusData = status.data.data;
    if (!profile.active) {
      statusData.generalInformation = 'INACTIVE';
    }
    setNavItemStatus(statusData);
  };

  useEffect(() => {
    void getNavItemStatuses();
  }, [selectedScreen, profile.active]);

  useEffect(() => {
    const getQuery = query.get('name');
    setSelectedScreen(getQuery || 'generalInformation');
  }, [query]);

  useEffect(() => {
    if (profile?.providerId) {
      dispatch(profileActionCreators.fetchProviderDetails(profile?.providerId));
      dispatch(scheduleActionCreators.fetchProviderSchedule({ userId: profile?.providerId }));
    }
  }, [profile?.providerId, providers]);

  const rightSideNavItems: INavItemType[] = useMemo(() => {
    const statusKeys = Object.keys(navItemStatus || {});
    return rightSideNavList.map(item => {
      const navItem = statusKeys.find(key => key === item.query.value);
      const status = navItemStatus?.[navItem] || '';
      switch (status) {
        case true:
          return {
            ...item,
            icon: 'checked-circle-outlined',
            activeIcon: 'checked-circle-outlined',
            classIcon: classes.successIcon,
          };
        case CardStatus.VERIFIED:
          return {
            ...item,
            icon: 'checked-circle-outlined',
            activeIcon: 'checked-circle-outlined',
            classIcon: classes.successIcon,
          };
        case 'COMPLETED':
          return {
            ...item,
            icon: 'checked-circle-outlined',
            activeIcon: 'checked-circle-outlined',
            classIcon: classes.successIcon,
          };
        case CardStatus.REVIEW_NEEDED:
          return {
            ...item,
            icon: 'question-outlined',
            activeIcon: 'question-outlined',
            classIcon: classes.infoIcon,
          };
        case CardStatus.DOCUMENT_ANALYSIS:
          return {
            ...item,
            icon: 'file-warning',
            activeIcon: 'file-warning',
            classIcon: classes.documentIcon,
          };
        case CardStatus.DOCUMENT_NEEDED:
          return {
            ...item,
            icon: 'file-warning',
            activeIcon: 'file-warning',
            classIcon: classes.documentIcon,
          };
        case CardStatus.DISCREPANCY:
          return {
            ...item,
            icon: 'alert-outlined',
            activeIcon: 'alert-outlined',
            classIcon: classes.discrepancyIcon,
          };
        case CardStatus.EXPIRED:
          return {
            ...item,
            icon: 'file-warning',
            activeIcon: 'file-warning',
            classIcon: classes.expiredIcon,
          };
        case CardStatus.EXPIRING_SOON:
          return {
            ...item,
            icon: 'file-warning',
            activeIcon: 'file-warning',
            classIcon: classes.expiringSoonIcon,
          };
        case CardStatus.MULTIPLE_STATUS:
          return {
            ...item,
            icon: 'alert-outlined',
            activeIcon: 'alert-outlined',
            classIcon: classes.multipleIcon,
          };
        case CardStatus.INCOMPLETE:
          return {
            ...item,
            icon: 'alert-outlined',
            activeIcon: 'alert-outlined',
            classIcon: classes.expiredIcon,
          };
        case CardStatus.FAILED:
          return {
            ...item,
            icon: 'alert-outlined',
            activeIcon: 'alert-outlined',
            classIcon: classes.expiredIcon,
          };
        case 'INACTIVE':
          return {
            ...item,
            icon: 'x-circle-outlined',
            activeIcon: 'x-circle-outlined',
            classIcon: classes.inactiveIcon,
          };
        default:
          return {
            ...item,
            icon: 'checked-circle-outlined',
            activeIcon: 'checked-circle-outlined',
            classIcon: classes.disabledIcon,
          };
      }
    });
  }, [rightSideNavList, navItemStatus]);

  return (
    <BaseLayout
      rightSideNavList={rightSideNavItems}
      rightSidebarHeadCustom={
        <Header label="My profile" fontVariant={HeadingLevel.SUB} fontFamily={HeadingFont.PRIMARY} />
      }
      rightSideBarWidth="280px"
    >
      <div className={classes.root}>
        <SelectedComponent
          selectedScreen={selectedScreen}
          providerId={profile?.providerId}
          refresh={() => void getNavItemStatuses()}
          navItemStatus={navItemStatus}
          setNavItemStatus={setNavItemStatus}
        />
      </div>
    </BaseLayout>
  );
});

ProfileV2.displayName = 'Profile';

export default ProfileV2;
