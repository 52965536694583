import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  Heading,
  headingLevel,
  Text,
  textLevel,
  fontWeight,
} from '@confidant-health/lib/ui/atoms/typography';
import { Box, Modal } from '@mui/material';
import { btnSize, btnType, Button } from '@confidant-health/lib/ui/atoms/button';
import { Icons } from '@confidant-health/lib/icons';
import { colors } from '@confidant-health/lib/colors';
import { Collapsable, IiconPosition } from '@confidant-health/lib/ui/atoms/collapsable';
import { iconBtnType, IconButton } from '@confidant-health/lib/ui/molecules/icon-button';
import insuranceImg from 'assets/images/insurance.png';
import dayjs from 'dayjs';
import { profileActionCreators } from 'redux/modules/profile/actions';
import { getProfile, selectMemberInsurance } from 'redux/modules/profile/selectors';
import { getStatesSimple } from 'services/state/state.service';
import { stateActionCreators } from 'redux/modules/state';
import { validateInsuranceInfo } from 'services/member/member.service';
import { showSnackbar } from 'redux/modules/snackbar';
import PriorityEmpty from 'pages/provider/member-detail/components/PriorityEmpty';
import InsuranceInformationEdit from './edit';
import { useStyles } from '../../MemberDetail.styles';
import { MediaManager } from '../../../../../services/mediaManager/mediaManager';

const InsuranceInformation = () => {
  const classes = useStyles({});
  const dispatch = useDispatch();
  const { memberId } = useParams();
  const { demographicDetails } = useSelector(getProfile);

  const [openInsuranceEdit, setOpenInsuranceEdit] = useState<boolean>(false);
  const [statesSimpleList, setStatesSimpleList] = useState(null);
  const [type, setType] = useState('insurance-front');
  const [loading, setLoading] = useState(false);
  const { isLoading, insurance } = useSelector(selectMemberInsurance);
  const [imagePreview, setImagePreview] = useState(null);
  const imageRef = useRef<HTMLInputElement>(null);
  const getAllStates = async () => {
    try {
      const response = await getStatesSimple();
      setStatesSimpleList(response?.data?.data || []);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    dispatch(profileActionCreators.fetchMemberInsurance({ memberId }));
    getAllStates()?.then(r => console.log(r));
  }, []);

  const onOpenFile = (fileType: string) => {
    setType(fileType);
    setTimeout(() => {
      imageRef.current?.click();
    }, 100);
  };

  const uploadFile = async (f: File) => {
    try {
      const res = await MediaManager.uploadMediaFiles(f, 'idCardImages/', null);
      if (res?.success) {
        return res.response;
      }
    } catch (error) {
      console.log({ error });
    }
    return null;
  };

  const submitIDCard = async e => {
    setLoading(true);
    const file = e.target.files[0];
    if (!file) {
      return;
    }

    const res = await uploadFile(file);

    if (res?.location) {
      dispatch(
        profileActionCreators.updateMemberInsurance({
          memberId,
          payload: {
            userAccountId: memberId,
            currentInsurance: insurance?.currentInsurance?._id,
            patientInsuranceData: {
              primaryInsured: insurance?.patientInsuranceData?.primaryInsured,
              fullName: insurance?.patientInsuranceData?.fullName,
              dob: insurance?.patientInsuranceData?.dob,
              memberId: insurance?.patientInsuranceData?.memberId,
              memberIdPhotoFront:
                type === 'insurance-front'
                  ? res?.location
                  : insurance?.patientInsuranceData?.memberIdPhotoFront,
              memberIdPhotoBack:
                type === 'insurance-back'
                  ? res?.location
                  : insurance?.patientInsuranceData?.memberIdPhotoBack,
              gender: insurance?.patientInsuranceData?.gender,
              relationshipToPrimarySubscriber:
                insurance?.patientInsuranceData?.relationshipToPrimarySubscriber,
              employerName: insurance?.patientInsuranceData?.employerName,
              employerGroupId: insurance?.patientInsuranceData?.employerGroupId,
            },
          },
        }),
      );
    }
    dispatch(profileActionCreators.fetchMemberInsurance({ memberId }));
    setLoading(false);
  };

  useEffect(() => {
    if (insurance?.currentInsurance?.stateId) {
      dispatch(stateActionCreators.fetchPayers(insurance?.currentInsurance?.stateId));
    }
  }, [openInsuranceEdit]);

  const onValidateClickHandler = () => {
    validateInsuranceInfo(memberId)
      .then(res => {
        if (!res.data?.success) {
          dispatch(
            showSnackbar({ snackType: 'error', snackMessage: res.data?.message || 'Something went wrong' }),
          );
          return;
        }
        dispatch(profileActionCreators.fetchMemberInsurance({ memberId }));
        dispatch(showSnackbar({ snackType: 'success', snackMessage: res.data?.message }));
      })
      .catch(error => {
        dispatch(
          showSnackbar({
            snackType: 'error',
            snackMessage: error.message || `Unable to validate insurance information.`,
          }),
        );
        dispatch(profileActionCreators.fetchMemberInsurance({ memberId }));
      });
  };

  const formatBooleanValue = value => {
    if (typeof value === 'boolean') {
      if (value === true) return 'Yes';
      return 'No';
    }
    return value;
  };

  const renderValue = (field: string, value: any) => {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Text level={textLevel.S} weight={fontWeight.SEMI_BOLD}>
          {field}
        </Text>
        {typeof value === 'string' ? (
          <Text level={textLevel.S} weight={fontWeight.MEDIUM} color={colors.neutral700}>
            {value}
          </Text>
        ) : (
          value
        )}
      </Box>
    );
  };

  const renderListItem = (field: string, value: any, firstItem = false, error = false) => {
    if (!value) return null;
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '1rem',
          borderTop: firstItem ? 'unset' : `1px solid ${colors.neutral100}`,
        }}
      >
        <Text level={textLevel.S} weight={fontWeight.SEMI_BOLD}>
          {field}
        </Text>
        <Text
          level={textLevel.S}
          weight={fontWeight.MEDIUM}
          color={error ? colors.destructive600 : colors.neutral700}
        >
          {value}
        </Text>
      </Box>
    );
  };

  return (
    <Box>
      {/* {errorMsg && <TextError errorMsg={errorMsg} />} */}
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box>
          <Heading className={classes.heading} level={headingLevel.XL} weight={fontWeight.BOLD}>
            Insurance Information
          </Heading>
        </Box>
        <Box className={classes.insuranceHeaderButtons}>
          {insurance && (
            <Button
              variant={btnType.SECONDARY}
              onClick={() => setOpenInsuranceEdit(true)}
              className={classes.uploadInsuranceBtn}
            >
              Edit
            </Button>
          )}
          {!insurance && (
            <IconButton
              className="addBtn"
              icon="plus"
              variant={iconBtnType.PRIMARY}
              onClick={() => setOpenInsuranceEdit(true)}
            >
              Add Insurance
            </IconButton>
          )}
        </Box>
      </Box>

      {(isLoading || loading) && (
        <Box display="flex" justifyContent="center" sx={{ paddingY: 5 }}>
          <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />
        </Box>
      )}
      {!isLoading && !insurance && (
        <Box className={classes.card} marginTop="40px">
          <PriorityEmpty
            title="Insurance information has not been updated yet."
            description=""
            glyph="lottie-insurance"
            glyphOrientation="portrait"
            isEmptyWithLottie
            lottieSize={128}
          />
        </Box>
      )}
      {insurance && (
        <>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4, marginTop: '40px' }}>
            <Box className={classes.contentCard}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                  <img src={insuranceImg} alt="insurance" style={{ height: '40px', width: '40px' }} />
                  <Heading level={headingLevel.S} weight={fontWeight.SEMI_BOLD} className={classes.heading2}>
                    Manually validate insurance benefits
                  </Heading>
                </Box>
                <Button size={btnSize.XSMALL} onClick={onValidateClickHandler}>
                  {insurance?.insuranceEligibilityStatus &&
                  insurance?.insuranceEligibilityStatus !== 'complete.eligible'
                    ? 'Validate again'
                    : 'Validate'}
                </Button>
              </Box>
              {insurance?.insuranceEligibilityStatus &&
                insurance?.insuranceEligibilityStatus !== 'complete.eligible' && (
                  <Collapsable
                    labelExtend={
                      <Box padding="1rem">
                        <span className={classes.errorText}>Verification Failed - </span>
                        <span className={classes.errorReasonText}>
                          {insurance?.insuranceEligibilityFailureReason}
                        </span>
                      </Box>
                    }
                    className={classes.errorCard}
                    iconPosition={IiconPosition.RIGHT}
                    iconColor={colors.neutral700}
                  >
                    <Box className={classes.errorCardList}>
                      {renderListItem(
                        'Insurance eligibility status',
                        insurance?.insuranceEligibilityStatus,
                        true,
                      )}
                      {renderListItem('Sohar system id', insurance?.soharSystemId)}
                      {renderListItem(
                        'Coverage end date',
                        insurance?.insuranceEligibilityDetail?.coverageStartDate,
                      )}
                      {renderListItem(
                        'Coverage start date',
                        insurance?.insuranceEligibilityDetail?.coverageEndDate,
                      )}
                      {renderListItem(
                        'In co-insurance',
                        insurance?.insuranceEligibilityDetail?.inn?.coinsurance,
                      )}
                      {renderListItem('In co-pay', insurance?.isCopay ? `$${insurance?.coPayAmount}` : 'N/A')}
                      {renderListItem(
                        'In prior auth required',
                        insurance?.insuranceEligibilityDetail?.inn?.priorAuthRequired ? 'Yes' : 'No',
                      )}
                      {renderListItem(
                        'Member ID',
                        insurance?.patientInsuranceData?.memberId,
                        false,
                        insurance?.insuranceEligibilityStatus === 'error.member.id',
                      )}
                      {renderListItem(
                        'Member DOB',
                        dayjs(insurance?.patientInsuranceData?.dob)?.format('MM/DD/YYYY'),
                        false,
                        insurance?.insuranceEligibilityStatus === 'error.member.dob',
                      )}
                      {renderListItem(
                        'Member name',
                        insurance?.patientInsuranceData?.fullName,
                        false,
                        insurance?.insuranceEligibilityStatus === 'error.member.name',
                      )}
                      {renderListItem(
                        'Payer',
                        insurance?.insuranceEligibilityDetail?.payerName,
                        false,
                        insurance?.insuranceEligibilityStatus === 'error.payer',
                      )}
                    </Box>
                  </Collapsable>
                )}
            </Box>
            <Box sx={{ display: 'flex', gap: 4, width: '100%' }}>
              <Box className={classes.contentCard}>
                {renderValue('Insurance', insurance?.currentInsurance?.name || 'N/A')}
                {renderValue('Member ID', insurance?.patientInsuranceData?.memberId || 'N/A')}
                {renderValue('Plan', insurance?.currentInsurance?.plans[0]?.name || 'N/A')}
                {renderValue(
                  'State',
                  statesSimpleList?.find(state => state?._id === insurance?.currentInsurance?.stateId)
                    ?.name || 'N/A',
                )}
              </Box>
              <Box className={classes.contentCard}>
                {renderValue(
                  'Eligible',
                  <Icons
                    glyph={insurance?.insuranceEligibilityEligible ? 'checkmark' : 'x-circle-outlined'}
                    color={
                      insurance?.insuranceEligibilityEligible ? colors.success500 : colors.destructive600
                    }
                  />,
                )}
                {renderValue(
                  'Primary Insured',
                  <Icons
                    glyph={
                      insurance?.patientInsuranceData?.primaryInsured ? 'checkmark' : 'x-circle-outlined'
                    }
                    color={
                      insurance?.patientInsuranceData?.primaryInsured
                        ? colors.success500
                        : colors.destructive600
                    }
                  />,
                )}
                {renderValue(
                  'Value based care client',
                  <Icons
                    glyph={demographicDetails?.vbcFlag ? 'checkmark' : 'x-circle-outlined'}
                    color={demographicDetails?.vbcFlag ? colors.success500 : colors.destructive600}
                  />,
                )}
                {renderValue(
                  'Co-pay',
                  <Icons
                    glyph={insurance?.isCopay ? 'checkmark' : 'x-circle-outlined'}
                    color={insurance?.isCopay ? colors.success500 : colors.destructive600}
                  />,
                )}
              </Box>
            </Box>
            <Box className={classes.contentCard}>
              {renderValue('Insured first name ', insurance?.patientInsuranceData?.firstName || 'N/A')}
              {renderValue('Insured last name', insurance?.patientInsuranceData?.lastName || 'N/A')}
              {renderValue(
                'Insured date of birth',
                dayjs(insurance?.patientInsuranceData?.dob)?.format('MM/DD/YYYY') || 'N/A',
              )}
              {renderValue('Insured gender', insurance?.patientInsuranceData?.gender || 'N/A')}
              {renderValue(
                'Coverage start date',
                insurance?.insuranceEligibilityDetail?.coverageStartDate || 'N/A',
              )}
              {renderValue(
                'Coverage end date',
                insurance?.insuranceEligibilityDetail?.coverageEndDate || 'N/A',
              )}
              {renderValue(
                'Deductible (Total/Remaining)',
                insurance?.insuranceEligibilityDetail?.inn?.totalDeductible &&
                  insurance?.insuranceEligibilityDetail?.inn?.remainingDeductible
                  ? `$${insurance?.insuranceEligibilityDetail?.inn?.totalDeductible} / $${insurance?.insuranceEligibilityDetail?.inn?.remainingDeductible}`
                  : 'N/A',
              )}
              {renderValue('Co-pay', insurance?.isCopay ? `$${insurance?.coPayAmount}` : 'N/A')}
              {renderValue(
                'Co-Insurance',
                insurance?.insuranceEligibilityDetail?.inn?.coinsurance
                  ? `${insurance?.insuranceEligibilityDetail?.inn?.coinsurance * 100}%`
                  : 'N/A',
              )}
              {renderValue(
                'Prior authorization required',
                <Icons
                  glyph={
                    insurance?.insuranceEligibilityDetail?.inn?.priorAuthRequired
                      ? 'checkmark'
                      : 'x-circle-outlined'
                  }
                  color={
                    insurance?.insuranceEligibilityDetail?.inn?.priorAuthRequired
                      ? colors.success500
                      : colors.destructive600
                  }
                />,
              )}
              {renderValue(
                'Validation date & time',
                dayjs(insurance?.insuranceEligibilityDetail?.timestamp)?.format('MM/DD/YYYY - h:mm a') ||
                  'N/A',
              )}
            </Box>
            <Box sx={{ display: 'flex', gap: 4 }}>
              <input
                type="file"
                accept="image/*"
                ref={imageRef}
                style={{ display: 'none' }}
                onChange={submitIDCard}
              />
              <Box className={classes.contentCard}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Text level={textLevel.S} weight={fontWeight.SEMI_BOLD}>
                    Insurance card front
                  </Text>
                  {insurance?.patientInsuranceData?.memberIdPhotoFront && (
                    <Icons
                      glyph="upload-file"
                      onClick={() => onOpenFile('insurance-front')}
                      className={classes.icon}
                    />
                  )}
                </Box>
                {insurance?.patientInsuranceData?.memberIdPhotoFront ? (
                  <div onClick={() => setImagePreview('front')}>
                    <img
                      className={classes.imagePreview}
                      src={insurance?.patientInsuranceData?.memberIdPhotoFront}
                      alt="front"
                    />
                  </div>
                ) : (
                  <Box className={classes.insuranceUploadCard}>
                    <IconButton
                      icon="upload-file"
                      variant={btnType.SECONDARY}
                      size={btnSize.XXSMALL}
                      className={classes.uploadInsuranceBtn}
                      onClick={() => onOpenFile('insurance-front')}
                    >
                      Upload
                    </IconButton>
                  </Box>
                )}
              </Box>
              <Box className={classes.contentCard}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Text level={textLevel.S} weight={fontWeight.SEMI_BOLD}>
                    Insurance card back
                  </Text>
                  {insurance?.patientInsuranceData?.memberIdPhotoBack && (
                    <Icons
                      glyph="upload-file"
                      onClick={() => onOpenFile('insurance-back')}
                      className={classes.icon}
                    />
                  )}
                </Box>
                {insurance?.patientInsuranceData?.memberIdPhotoBack ? (
                  <div onClick={() => setImagePreview('back')}>
                    <img
                      className={classes.imagePreview}
                      src={insurance?.patientInsuranceData?.memberIdPhotoBack}
                      alt="back"
                    />
                  </div>
                ) : (
                  <Box className={classes.insuranceUploadCard}>
                    <IconButton
                      icon="upload-file"
                      variant={btnType.SECONDARY}
                      size={btnSize.XXSMALL}
                      className={classes.uploadInsuranceBtn}
                      onClick={() => onOpenFile('insurance-back')}
                    >
                      Upload
                    </IconButton>
                  </Box>
                )}
              </Box>
            </Box>
            {/* <Box className={classes.contentCard}> */}
            {/*   <Box sx={{ display: 'flex', justifyContent: 'space-between' }}> */}
            {/*     <Box sx={{ display: 'flex', alignItems: 'center', gap: '16px' }}> */}
            {/*       <img src={insuranceImg} alt="insurance" style={{ height: '40px', width: '40px' }} /> */}
            {/*       <Heading level={headingLevel.S} weight={fontWeight.SEMI_BOLD} className={classes.heading2}> */}
            {/*         Add Secondary Insurance */}
            {/*       </Heading> */}
            {/*     </Box> */}
            {/*     <Button size={btnSize.SMALL}>Add insurance</Button> */}
            {/*   </Box> */}
            {/* </Box> */}
          </Box>
        </>
      )}
      <Modal open={imagePreview === 'front' || imagePreview === 'back'} onClose={() => setImagePreview(null)}>
        <Box className={classes.previewModal}>
          <Box className={classes.previewModalContent}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <Heading level={headingLevel.S} weight={fontWeight.SEMI_BOLD}>
                {`Insurance card ${imagePreview}`}
              </Heading>
              <Icons
                glyph="close"
                onClick={() => setImagePreview(null)}
                color={colors.neutral700}
                className={classes.icon}
              />
            </Box>
            <img
              style={{ height: '373px', width: 'auto', maxWidth: '100%', objectFit: 'contain' }}
              src={
                imagePreview === 'front'
                  ? insurance?.patientInsuranceData?.memberIdPhotoFront
                  : insurance?.patientInsuranceData?.memberIdPhotoBack
              }
              alt="preview"
            />
          </Box>
        </Box>
      </Modal>
      {openInsuranceEdit && (
        <InsuranceInformationEdit
          openDrawer={openInsuranceEdit}
          onClose={() => setOpenInsuranceEdit(false)}
          data={insurance}
          states={statesSimpleList}
          vbc={demographicDetails.vbcFlag}
          patientInfo={demographicDetails}
        />
      )}
    </Box>
  );
};

export default InsuranceInformation;
