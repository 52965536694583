import { AxiosResponse } from 'axios';
import { baseRequest } from 'utils/HttpClient';
import { ApiEndpoints } from 'constants/ApiEndpoints';
import { IReportDTO } from 'redux/modules/reporting/types';

export const getContentBlockHistory = (pathParams: {
  contentBlockId: string;
  userId: string;
}): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.GET_CONTENT_BLOCK_HISTORY, null, pathParams);
};

export const getReportList = (payload: {
  searchQuery: string;
  pageNumber: number;
  pageSize: number;
  orderBy?: string;
  sortBy?: string;
}): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.GET_REPORTS_LIST, null, null, payload);
};

export const getReportById = (id: string): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.GET_REPORT_BY_ID, null, { id }, null);
};

export const getKPIOutcome = (kpi, query): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.GET_KPI_OUTCOME_FOR_MEMBER, null, { kpi }, query);
};

export const getProfileElementAssignmentIn30Days = (params: {
  userAccountId: string;
  profileElement: string;
}): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.GET_PROFILE_ELEMENT_30_DAY_ASSIGNMENT, null, params);
};

export const addReport = (payload: IReportDTO): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.ADD_NEW_REPORT, payload, null);
};

export const updateReport = (
  id: string,
  payload: {
    active: boolean;
    serviceId: string;
  },
): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.UPDATE_REPORT, payload, { id }, null);
};

export const deleteReport = (id: string): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.DELETE_REPORT, null, { id });
};

export const getElkPatientReport = (body, params) => {
  return baseRequest(ApiEndpoints.GET_ELK_PATIENT_REPORT, body, null, params, false, false);
};

export const getElkAppointmentReport = (body, params) => {
  return baseRequest(ApiEndpoints.GET_ELK_APPOINTMENT_REPORT, body, null, params, false, false);
};

export const getElkAppointmentCount = (body, params) => {
  return baseRequest(ApiEndpoints.GET_ELK_APPOINTMENT_COUNT, body, null, params, false, false);
};

export const getElkAppointmentFeedback = (body, params) => {
  return baseRequest(ApiEndpoints.GET_ELK_APPOINTMENT_FEEDBACK, body, null, params, false, false);
};

export const getEvaluationDctOutcomes = (params: { userId: string }) => {
  return baseRequest(ApiEndpoints.GET_EVALUATIONS_DCT_OUTCOME_FOR_USER, null, params);
};

export const getPatientEvaluation = (
  queryParams: {
    pageNumber: number;
    pageSize: number;
  },
  params: { userId: string },
) => {
  return baseRequest(ApiEndpoints.GET_PATIENT_EVALUATIONS, null, params, queryParams);
};

export const getCompletionHistory = (bodyParams: { userId: string; evaluationId: string }) => {
  return baseRequest(ApiEndpoints.GET_COMPLETION_HISTORY, bodyParams);
};

export const getProviderCompletionHistory = (bodyParams: { userId: string; dctId: string }) => {
  return baseRequest(ApiEndpoints.GET_PROVIDER_COMPLETION_HISTORY, bodyParams);
};

export const getMemberCompletionHistory = (bodyParams: { userId: string; dctId: string }) => {
  return baseRequest(ApiEndpoints.GET_MEMBER_COMPLETION_HISTORY, bodyParams);
};

export const getContextResponses = (params: { contextId: string; dctId?: string }) => {
  return baseRequest(ApiEndpoints.GET_CONTEXT_RESPONSES, null, params);
};

export const getMemberContextResponses = (params: { contextId: string; dctId?: string }) => {
  return baseRequest(
    ApiEndpoints.GET_MEMBER_CONTEXT_RESPONSES,
    null,
    { contextId: params.contextId },
    { dctId: params.dctId },
  );
};

export const getReportDctForPatient = (params: { userId: string }) => {
  return baseRequest(ApiEndpoints.GET_REPORT_DCT_FOR_PATIENT, null, params);
};

export const getDctResponses = (params: { userId: string; cbId: string; beforeDate: number }) => {
  const { beforeDate, ...pathParams } = params;
  return baseRequest(ApiEndpoints.GET_DCT_RESPONSES, null, pathParams, { beforeDate });
};

export const getElkProfileElementReport = (body, params) => {
  return baseRequest(ApiEndpoints.GET_ELK_PROFILE_ELEMENT_REPORT, body, null, params, false, false);
};
export const getElkProfileElementReport2 = (body, params) => {
  return baseRequest(ApiEndpoints.GET_ELK_PROFILE_ELEMENT_REPORT_2, body, null, params, false, false);
};

export const getElkProfileElementCount = (body, params) => {
  return baseRequest(
    ApiEndpoints.GET_ELK_PROFILE_ELEMENT_COUNT,
    body || {},
    null,
    params || {},
    false,
    false,
  );
};

export const getElkChatbotDctReport = (body, params) => {
  return baseRequest(ApiEndpoints.GET_ELK_CHATBOT_DCT_REPORT, body, null, params, false, false);
};
export const getElkChatbotDctCount = (body, params) => {
  return baseRequest(ApiEndpoints.GET_ELK_CHATBOT_DCT_COUNT, body, null, params, false, false);
};

export const getElkEvaluationDctReport = (body, params) => {
  return baseRequest(ApiEndpoints.GET_ELK_EVALUATION_DCT_REPORT, body, null, params, false, false);
};
export const getElkEvaluationDctCount = (body, params) => {
  return baseRequest(ApiEndpoints.GET_ELK_EVALUATION_DCT_COUNT, body, null, params, false, false);
};
export const getElkChatbotReport = (body, params) => {
  return baseRequest(ApiEndpoints.GET_ELK_CHATBOT_REPORT, body, null, params, false, false);
};

export const getElkChatbotCount = (body, params) => {
  return baseRequest(ApiEndpoints.GET_ELK_CHATBOT_COUNT, body, null, params, false, false);
};
export const getElkEvaluationReport = (body, params) => {
  return baseRequest(ApiEndpoints.GET_ELK_EVALUATION_REPORT, body, null, params, false, false);
};
export const getElkEvaluationCount = (body, params) => {
  return baseRequest(ApiEndpoints.GET_ELK_EVALUATION_COUNT, body, null, params, false, false);
};

export const getElkDataDomainElementReport = (body, params) => {
  return baseRequest(ApiEndpoints.GET_ELK_DATA_DOMAIN_ELEMENT_REPORT, body, null, params, false, false);
};
export const getElkDataDomainElementCount = (body, params) => {
  return baseRequest(ApiEndpoints.GET_ELK_DATA_DOMAIN_ELEMENT_COUNT, body, null, params, false, false);
};

export const getElkPatientCount = (body = {}, params = {}) => {
  return baseRequest(ApiEndpoints.GET_ELK_PATIENTS_COUNT, body, null, params, false, false);
};

export const getElkReportsCount = (body = {}, params = {}) => {
  return baseRequest(ApiEndpoints.GET_ELK_REPORTS_COUNT, body, null, params, false, false);
};

export const getElkLoginReport = (body, params) => {
  return baseRequest(ApiEndpoints.GET_ELK_LOGIN_REPORT, body, null, params, false, false);
};

export const getElkLoginCount = (body, params) => {
  return baseRequest(ApiEndpoints.GET_ELK_LOGIN_COUNT, body, null, params, false, false);
};

export const getClaims = (body, params) => {
  return baseRequest(ApiEndpoints.GET_CLAIMS, body, null, params);
};
export const downloadClaims = (payload: any, requestBody: any): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.GET_CLAIMS, requestBody || {}, null, payload, true, true, true);
};
export const getClaimById = (pathParams: { id: string }) => {
  return baseRequest(ApiEndpoints.GET_CLAIM_BY_ID, null, pathParams);
};

export const updateClaimById = (
  requestBody: Record<string, unknown>,
  pathParams: { claimId: string; type: string },
): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.UPDATE_CLAIM_BY_ID, requestBody, pathParams, null, true);
};

export const createInvoice = params => {
  return baseRequest(ApiEndpoints.ADD_INVOICE, params, null, null);
};

export const getInvoices = (body, params, exportInvoices = false) => {
  return baseRequest(ApiEndpoints.GET_INVOICES, body, null, params, true, true, exportInvoices);
};

export const exportAppointmentStats = () => {
  return baseRequest(ApiEndpoints.EXPORT_APPOINTMENT_STATS, null, null, null, true, true, true);
};

export const updateProviderFeeByProviderId = (
  requestBody: Record<string, unknown>,
  pathParams: { providerId: string },
): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.UPDATE_PROVIDER_FEE_BY_PROVIDER_ID, requestBody, pathParams, null, true);
};

export const getInvoiceById = (pathParams: { id: string }) => {
  return baseRequest(ApiEndpoints.GET_INVOICE_BY_ID, null, pathParams);
};

export const updateInvoiceById = (
  requestBody: Record<string, unknown>,
  pathParams: { id: string },
): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.UPDATE_INVOICE_BY_ID, requestBody, pathParams, null, true);
};

export const updateInvoiceStatus = (
  requestBody: Record<string, unknown>,
  pathParams: { id: string },
): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.UPDATE_INVOICE_STATUS, requestBody, pathParams, null, true);
};

export const getElkPlanItemsDetail = (body, params) => {
  return baseRequest(ApiEndpoints.GET_ELK_PLAN_ITEM_DETAILS, body, null, params, false, false);
};

export const getElkAutomationDetail = (body, params) => {
  return baseRequest(ApiEndpoints.GET_ELK_AUTOMATION_DETAILS, body, null, params, false, false);
};

export const downloadExcelReport = (requestBody: {
  report: string;
  filter: string;
}): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.DOWNLOAD_EXCEL_REPORT, requestBody, null, null, true, true, true);
};

export const getPhq9Gad7AverageScoreSummary = (
  params,
  pathParams: { type: string },
): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.GET_PHQ9_GAD7_AVERAGE_SCORE_SUMMARY, null, pathParams, params, true);
};

export const getAllPhq9Gad7AverageScore = (params, pathParams: { type: string }): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.GET_ALL_PHQ9_GAD7_AVERAGE_SCORE, null, pathParams, params, true);
};

export const uploadCandidPartOne = (requestBody: any) => {
  return baseRequest(
    ApiEndpoints.UPLOAD_CANDID_PART_ONE,
    requestBody,
    null,
    null,
    true,
    true,
    false,
    true,
    'file',
    false,
  );
};

export const uploadCandidPartTwo = (requestBody: any) => {
  return baseRequest(
    ApiEndpoints.UPLOAD_CANDID_PART_TWO,
    requestBody,
    null,
    null,
    true,
    true,
    false,
    true,
    'file',
    false,
  );
};

export const uploadCandidPartThree = (requestBody: any) => {
  return baseRequest(
    ApiEndpoints.UPLOAD_CANDID_PART_THREE,
    requestBody,
    null,
    null,
    true,
    true,
    false,
    true,
    'file',
    false,
  );
};

export const uploadCandidPartFour = (requestBody: any) => {
  return baseRequest(
    ApiEndpoints.UPLOAD_CANDID_PART_FOUR,
    requestBody,
    null,
    null,
    true,
    true,
    false,
    true,
    'file',
    false,
  );
};
